








































import { Component, Vue } from "vue-property-decorator";
import { State, Mutation } from "vuex-class";
import { erase as eraseCookie } from "browser-cookies";

import { PATHS } from "./router";
import { User } from "./util";



@Component({
    metaInfo: {
        title: "LSS TaxiApp"
    }
})
export default class App extends Vue
{
    @State user!: User;
    @Mutation erase_user!: () => void

    public paths = PATHS;

    public logout() {
        eraseCookie("token");
        this.erase_user();
        this.$router.push({ path: "/login" });
    }
}
