/* eslint-disable */


export function military2ampm(timestring: string): string {
    if(!timestring || timestring.length === 0) { return ""; }
    const [ hourStr, minuteStr ] = timestring.split(":");
    const hour = parseInt(hourStr, 10);
    const suffix = hour >= 12 ? "pm" : "am";
    const newHourStr = (hour === 0 || hour === 12) ? 12 : suffix === "pm" ? hour - 12 : hour;
    return `${newHourStr}:${minuteStr}${suffix}`;
}
