




































































import { Component, Vue } from "vue-property-decorator";

import { create_user, get_orgs} from "../api";
import { check_user, ROUTES } from "../util";
import { Role } from "../model/roles";

@Component({  })
export default class Form extends Vue
{
    public user = this.$store.state.user;

    public form: any = {  };

    public roles: any = [  ];
    public orgs: any = [  ];


    async create_user()
    {
        try {
            const result = await create_user(this.form);
            console.log(result);
        } catch(err){ console.error(err); }
    }

    async beforeCreate()
    {
        this.user = await check_user(ROUTES.LOGIN);
        this.user.check_role(this.$store.getters.roles.MANAGER, ROUTES.LOGIN);

        this.roles = this.$store.state.roles.map((role: Role) => {
            return {
                text: role.name,
                value: role._id
            };
        });

        //Load organizations into orgs array
        const response = await get_orgs();
        this.orgs = response.data.results.map((org: any) => {
            return {
                text: org.name,
                value: org._id
            };
        });
    }
}
