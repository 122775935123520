














































import { Component, Vue } from "vue-property-decorator";

import { add_org } from "../api";


@Component({  })
export default class CreateOrg extends Vue
{
    public form: any = {   };

    async add_org() {
        try {
            let result = await add_org(this.form);
            console.log(result);
        } catch(err) { console.error(err); }

    }
}
