























































import { Component, Vue } from "vue-property-decorator";

import { APIError, login } from "../api";
import { IAlert, load_user } from "../util";



@Component
export default class LoginPage extends Vue {
    public alert: IAlert = null;

    public form = {
        username: "",
        password: ""
    };
    public show: boolean = false;

    public created() {
        if(this.$store.state.user !== null) {
            this.$router.push({ path: "/" });
        }
    }

    public async login() {
        this.alert = null;
        try {
            await login(this.form);
            load_user();
            this.$router.push({ path: "/" });
        } catch(err) {
            if(err instanceof APIError) {
                this.alert = {
                    type: "error",
                    message: err.message
                };
            } else {
                console.error(err);
                this.alert = {
                    type: "error",
                    message: "An unexpected error ocurred. Please try again."
                };
            }
        }
    }
}
