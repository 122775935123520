import { get as getCookie } from "browser-cookies";

import store from "./store";
import router, { ROUTES } from "./router";
import { get_roles } from "./api";
import { User } from "./model/user";



export { ROUTES } from "./router";
export { User } from "./model/user";



export async function fetch_roles()
{
    if(store.state.roles.length > 0) { return; }
    const response = await get_roles();
    if(!response.data || response.data.status !== "success" ) {
        console.error("Malformed roles request response?");
        console.error(response);
        return;
    }
    store.commit("set_roles", response.data.results);
}

export async function load_user()
{
    const token = getCookie("token");
    if(!token) { return null; }
    const payload = token.split(".")[1];
    const user_obj = JSON.parse(Buffer.from(payload, "base64").toString("utf8")).user;
    await fetch_roles();
    const user = new User(user_obj.name, user_obj.username, user_obj.org, user_obj.role);
    store.commit("set_user", user);
    return user;
}
export async function check_user(): Promise<User>;
export async function check_user(fail_redirect: string | null): Promise<User>;
export async function check_user(fail_redirect: string | null, success_redirect: string): Promise<User>;
export async function check_user(fail_redirect: string | null = ROUTES.LOGIN, success_redirect: string | null = null): Promise<User>
{
    // Check if user in store
    let user = store.state.user as null | User;
    console.log("Checking Users!");
    console.log(user);
    // If not, check if it's in the token cookie
    if(!user || user.username.length === 0) { user = await load_user();}
    if(!user && fail_redirect) { router.push({ name: fail_redirect }); }
    else if(user && success_redirect) { router.push({ name: success_redirect }); }
    return user as User;
}



export type SelectOption = { text: string, value: string };
export function select_values(items: string[]): SelectOption[];
export function select_values(items: any[], text_field: string, value_field: string): SelectOption[];
export function select_values(items: any[], text_field?: string, value_field?: string): SelectOption[]
{
    if(items.length == 0) { return [  ]; }
    else if(text_field && value_field)
    {
        return items.map((item: any) => { return {
            text: item[text_field],
            value: item[value_field]
        }; });
    }
    else
    {
        return items.map((item: string) => { return {
            text: item,
            value: item
        }; });
    }
}


export type VForm = Vue & {
    validate:() => boolean;
    resetValidation: () => boolean;
    reset: () => void;
}

export type IAlert = null | {
    type: "success" | "info" | "warning" | "error";
    message: string;
}



export const singlePhoneMask = "(###) ###-####";

export function padnum(num: number, width: number): string {
    const numstr = num.toString(10);
    return "0".repeat(Math.max(0, width - numstr.length)) + numstr;
}
export function toLocaleISOString(date: Date): string {
    const tzo = date.getTimezoneOffset();
    const tzstr = tzo === 0 ? "Z" : "-" + (tzo % 60 === 0 ? padnum(tzo / 60, 2) : `${padnum(Math.floor(tzo / 60), 2)}:${padnum(tzo % 60, 2)}`);
    const yearstr = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    const timestr = `${date.getHours()}:${date.getMinutes()}`;
    return `${yearstr}T${timestr}${tzstr}`;
}
