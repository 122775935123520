















import { Component, Vue } from "vue-property-decorator";
import { State } from "vuex-class";
import { User } from "../util";
import CreateOrg from "../components/CreateOrg.vue";

@Component({
    components: {
        CreateOrg
    }
})
export default class Home extends Vue
{
    @State user!: User;

    public form: any = {  };
}
