



















































import { Component, Vue } from "vue-property-decorator";
import { State } from "vuex-class";

import { IUser } from "@/common/User";
import { User } from "../util";
import { delete_user, get_users } from "../api";



@Component
export default class ManageUsers extends Vue
{
    @State user!: IUser;
    public users: User[] = [];

    async mounted()
    {
        console.log("Fetching users...");
        await this.fetch_users();
    }

    async fetch_users()
    {
        try {
            let result = await get_users();
            // console.log("RESULTS:   ");
            // console.log(result);
            if(result.data && result.data.results.length > 0) {
                this.users = result.data.results;
                // console.log(this.users);
            } else {
                this.users = [  ];
            }
        } catch(err) { console.error(err); }
    }


    async delete_user(user: User)
    {
        try {
            // TODO: send id not user
            let result = await delete_user(user.username);
            await this.fetch_users();
        } catch(err) { console.error(err); }
    }


    async edit_user(user: User)
    {
        try {
            this.$router.push({
                name: "edit_user",
                params: { username: user.username }
            });
        } catch(err) { console.error(err); }
    }
}
