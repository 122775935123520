









































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Ref, Prop } from "vue-property-decorator";
import { State } from "vuex-class";

import { military2ampm } from "../common/util";

import { TaxiRequest, TaxiLeg, VehicleType, TaxiLocation } from "../model/taxi-request";
import { User } from "../model/user";
import { submit_taxi_request, edit_request, get_request } from "../api";
import { IAlert, select_values, singlePhoneMask, VForm } from "../util";



@Component
export default class ReportForm extends Vue
{
    @State user!: User;

    @Ref("form") readonly formRef!: VForm;

    @Prop({ required: true }) formType!: "new" | "edit";

    public REASON_DEFAULTS = select_values(TaxiRequest.REASON_DEFAULTS);
    public SERVICE_DEFAULTS = select_values(TaxiRequest.SERVICE_DEFAULTS);
    public REGIONS = select_values(TaxiRequest.REGIONS);
    public VEHICLE_TYPES = TaxiRequest.VEHICLE_TYPES;

    public raw_dates: string[] = [];
    public dates: string[] = [];

    /** Only used on "create" forms for an optional initial comment. */
    public commentField = "";
    public form = TaxiRequest.default();

    public childAgeArray: string[] = [];

    public reason = this.form.reason;
    public service = this.form.service;

    public date_menu = false;
    public time_menus = [ false, false, false, false ];
    public formatted_dates: string = "";
    public editResult: any[] = [];

    public alert: IAlert = null;



    public readonly CHILD_AGES = [
        "Less than 1 year old",
        "1", "2", "3", "4", "5", "6", "7", "8", "9",
        "10", "11", "12", "13", "14", "15", "16", "17"
    ];



    public async mounted() {
        if(this.$route.query["_id"] && this.formType === "edit") {
            await this.editTaxiRequest();
        }
    }



    public reason_input(value: string) {
        this.form.reason = value == "Other" ? "" : value;
    }
    public service_input(value: string) {
        this.form.service = value == "Other" ? "" : value;
    }

    // TODO: Review date_input() logic
    public date_input() {
        this.dates = this.raw_dates.map((raw_date) => {
            const [ year, month, date ] = raw_date.split("-");
            return `${month}/${date}/${year}`;
        });
    }

    public time_input(value: string, index: number) {
        this.form.legs[index].time = value;
        this.time_menus.splice(index, 1, false);
    }

    public clientPhoneMask = singlePhoneMask;
    public officePhoneMask = singlePhoneMask;
    public mobilePhoneMask = singlePhoneMask;
    public clientPhoneInput(value: string) {
        const sanitized = value.replace(/[^\d,]/g, "");
        this.clientPhoneMask = singlePhoneMask;
        if(sanitized.length <= 10) { return; }
        const numbers = Math.ceil((sanitized.length + 1) / 11);
        for(let i = 1; i < numbers; i++) {
            this.clientPhoneMask += ", " + singlePhoneMask;
        }
    }
    public officePhoneInput(value: string) {
        const sanitized = value.replace(/[^\d,]/g, "");
        this.officePhoneMask = singlePhoneMask;
        if(sanitized.length <= 10) { return; }
        const numbers = Math.ceil((sanitized.length + 1) / 11);
        for(let i = 1; i < numbers; i++) {
            this.officePhoneMask += ", " + singlePhoneMask;
        }
    }
    public mobilePhoneInput(value: string) {
        const sanitized = value.replace(/[^\d,]/g, "");
        this.mobilePhoneMask = singlePhoneMask;
        if(sanitized.length <= 10) { return; }
        const numbers = Math.ceil((sanitized.length + 1) / 11);
        for(let i = 1; i < numbers; i++) {
            this.mobilePhoneMask += ", " + singlePhoneMask;
        }
    }

    public vehicle_rules(value: VehicleType) {
        if(value.rule) {
            return value.rule(
                this.form.adults,
                this.form.children,
                this.form.carseats
            );
        }
        return false;
    }


    /******************
     * Child Age Code *
     ******************/
    public addChild() {
        this.form.children++;
        this.childAgeArray = [ ...this.childAgeArray, "" ];
        this.updateChildAges();
    }
    public removeChild() {
        if(this.form.children === 0) { return; }
        this.form.children--;
        this.childAgeArray = this.childAgeArray.slice(0, this.childAgeArray.length - 1);
        this.updateChildAges();
    }
    public setChildAge(index: number, newValue: string) {
        this.childAgeArray[index] = newValue;
        this.updateChildAges();
    }
    private updateChildAges() {
        this.form.child_age = this.childAgeArray.join(", ");
    }



    public military2ampm(timestring: string) { return military2ampm(timestring); }

    public addLeg() {
        this.form.legs.push(TaxiLeg.default("Yes"));
    }
    public removeLeg() {
        this.form.legs.pop();
    }
    public returnLeg(index: number) {
        if(index == 0) {
            return;
        }
        this.form.legs.splice(index, 1, this.form.legs[index - 1].reverse());
    }

    public keysInOrder = [
        "reason",
        "service",
        "caseworker",
        "region",
        "supervisor",
        "caller",
        "email",
        "office_phone",
        "case_id",
        "mobile_phone",
        "client",
        "client_phone",
        "date",
        "adult",
        "children",
        "child_age",
        "comments",
        "vehicle_type",
        "carseat",
        "legs"
    ];

    async submitTaxiRequest() {
        // Validate user input
        // TODO: Verify this works
        if(!this.formRef.validate()) {
            console.log("Form not valid.");
            this.$emit("formInvalid");
            return;
        }
        try {
            if(this.form.reason !== "Other") {
                this.form.reason = this.reason;
            }
            if(this.form.service !== "Other") {
                this.form.service = this.service;
            }
            if(this.commentField.length > 0 && this.form.comments.length === 0) {
                // Use the length guard to prevent duplicate comments on multiple submit attempts.
                this.form.comments.push({
                    text: this.commentField,
                    author: this.$store.state.user["name"],
                    date: new Date().toISOString()
                });
            }

            // Got to normalize in order to get rid of the Vue crud that v-models build around this.form
            for(const event in this.dates) {
                this.form.date = this.dates[event];
                this.form.ufn = this.dates.length > 1;
                this.form.who = this.$store.state.user["username"];
                const request = new TaxiRequest(this.form);
                const response = await submit_taxi_request(request);
                const result = response.data;
                console.log(result);
            }

            // For Success Modal
            this.$emit("formSuccess");

            // TO-DO: Success/Error message
        } catch(err) {
            console.error(err);
            this.$emit("formError", err);
        }
    }

    async editTaxiRequest() {
        console.log("Binding the data to form...");
        try {
            const request = await get_request(this.$route.query["_id"]);
            console.log(`Request being edited: ${request._id}`);

            if(TaxiRequest.REASON_DEFAULTS.includes(request["reason"])) {
                this.reason = request["reason"];
            } else {
                this.reason = "Other";
                this.form.reason = request["reason"];
            }

            if(TaxiRequest.SERVICE_DEFAULTS.includes(request["service"])) {
                this.service = request["service"];
            } else {
                this.service = "Other";
                this.form.service = request["service"];
            }

            this.form.caseworker = request["caseworker"];

            if(TaxiRequest.REGIONS.includes(request["caseworker_region"])) {
                this.form.caseworker_region = request["caseworker_region"];
            } else {
                let regionsLet = [ "A", "C", "E", "Y", "I", "K", "W" ];
                if(regionsLet.includes(request.caseworker_region)) {
                    this.form.caseworker_region =
                        TaxiRequest.REGIONS[
                            regionsLet.indexOf(request["caseworker_region"])
                        ];
                }
            }

            this.form.supervisor = request["supervisor"];
            this.form.caller = request["caller"];
            this.form.office_phone = request["office_phone"];
            this.form.email = request["email"];
            this.form.case_id = request["case_id"];
            this.form.mobile_phone = request["mobile_phone"];
            this.form.client = request["client"];
            this.form.client_phone = request["client_phone"];
            this.form.date = request["date"];
            this.dates.push(request["date"]);
            this.formatted_dates = this.form.date;
            this.form.adults = request["adults"];
            this.form.children = request["children"];
            this.form.child_age = request["child_age"];
            if(this.form.child_age) {
                this.childAgeArray = this.form.child_age.split(/,\s*/).filter((age) => age);
            }
            this.form.comments = request["comments"];
            this.form.carseats = request["carseats"];

            if(TaxiRequest.vehicleTypeArray.includes(request["vehicle_type"])) {
                this.form.vehicle_type = request["vehicle_type"];
            }

            //leg1
            this.form.legs[0].pickup.address = request.legs[0]["pickup"]["address"];
            this.form.legs[0].pickup.zip = request.legs[0]["pickup"]["zip"];
            this.form.legs[0].destination.address = request.legs[0]["destination"]["address"];
            this.form.legs[0].destination.zip = request.legs[0]["destination"]["zip"];
            this.form.legs[0].time = request.legs[0]["time"];
            this.form.legs[0].willcall = request.legs[0]["willcall"];

            //leg2
            if(request.legs[1]) {
                this.form.legs.push(
                    new TaxiLeg(TaxiLocation.default(), TaxiLocation.default())
                );
                this.form.legs[1].pickup.address =
                    request.legs[1]["pickup"]["address"];
                this.form.legs[1].pickup.zip = request.legs[1]["pickup"]["zip"];
                this.form.legs[1].destination.address =
                    request.legs[1]["destination"]["address"];
                this.form.legs[1].destination.zip =
                    request.legs[1]["destination"]["zip"];
                this.form.legs[1].time = request.legs[1]["time"];
                this.form.legs[1].willcall = request.legs[1]["willcall"];
            }

            //leg3
            if(request.legs[2]) {
                this.form.legs.push(
                    new TaxiLeg(TaxiLocation.default(), TaxiLocation.default())
                );
                this.form.legs[2].pickup.address =
                    request.legs[2]["pickup"]["address"];
                this.form.legs[2].pickup.zip = request.legs[2]["pickup"]["zip"];
                this.form.legs[2].destination.address =
                    request.legs[2]["destination"]["address"];
                this.form.legs[2].destination.zip =
                    request.legs[2]["destination"]["zip"];
                this.form.legs[2].time = request.legs[2]["time"];
                this.form.legs[2].willcall = request.legs[2]["willcall"];
            }

            //leg4
            if(request.legs[3]) {
                this.form.legs.push(
                    new TaxiLeg(TaxiLocation.default(), TaxiLocation.default())
                );
                this.form.legs[3].pickup.address =
                    request.legs[3]["pickup"]["address"];
                this.form.legs[3].pickup.zip = request.legs[3]["pickup"]["zip"];
                this.form.legs[3].destination.address =
                    request.legs[3]["destination"]["address"];
                this.form.legs[3].destination.zip =
                    request.legs[3]["destination"]["zip"];
                this.form.legs[3].time = request.legs[3]["time"];
                this.form.legs[3].willcall = request.legs[3]["willcall"];
            }
        } catch(err) {
            console.error(err);
            this.$emit("formError", err);
        }
    }

    async cancelEditRequest() {
        //   this.$router.push({ path: "/" });
        this.$router.back();
    }

    async submitEditTaxiRequest() {
        // Validate user input
        if(!this.formRef.validate()) {
            console.log("Form not valid.");
            return;
        }

        try {
            if(this.form.reason !== "Other") {
                this.form.reason = this.reason;
            }
            if(this.form.service !== "Other") {
                this.form.service = this.service;
            }
            // Got to normalize in order to get rid of the Vue crud that v-models build around this.form
            for(const event in this.dates) {
                this.form.date = this.dates[event];
                this.form.ufn = this.dates.length > 1;
                this.form.who = this.$store.state.user["username"];

                const request = new TaxiRequest(this.form);
                request._id =
                    typeof this.$route.query["_id"] === "string"
                        ? this.$route.query["_id"]
                        : this.$route.query["_id"][0] || "";
                if(!request._id) {
                    throw new Error(
                        `Cannot parse request ID from: ${this.$route.query["_id"]}`
                    );
                }
                const response = await edit_request(request);
                const result = response.data;
                console.log(result);
            }

            // For Success Modal
            this.$emit("formSuccess");
        } catch(err) {
            console.error(err);
        }
    }

    async submitNewTaxiRequest() {
        // Validate user input
        if(!this.formRef.validate()) {
            console.log("Form not valid.");
            return;
        }
        try {
            if(this.form.reason !== "Other") {
                this.form.reason = this.reason;
            }
            if(this.form.service !== "Other") {
                this.form.service = this.service;
            }
            // Got to normalize in order to get rid of the Vue crud that v-models build around this.form
            for(const event in this.dates) {
                this.form.date = this.dates[event];
                this.form.ufn = this.dates.length > 1;
                this.form.who = this.$store.state.user["username"];
                const request = new TaxiRequest(this.form);
                const response = await submit_taxi_request(request);
                const result = response.data;
                console.log(result);
            }

            // For Success Modal
            this.$emit("formSuccess");
        } catch(err) {
            console.error(err);
        }
    }

    public rules = {
        reason: [
            (v: any) =>
                !!v ||
                "Please select the reason the client cannot use other transportation resources"
        ],
        reasonOther: [
            (v: any) =>
                !!v ||
                "Please describe the reason the client cannot use other transportation resources"
        ],
        service: [ (v: any) => !!v || "Please select the type of service." ],
        serviceOther: [
            (v: any) => !!v || "Please describe the type of service."
        ],
        caseworker: [
            (v: any) => !!v || "Please enter the caseworker's name.",
            (v: any) =>
                /^[a-zA-Z-' ]*$/.test(v) ||
                "Only letters, apostrophes, dashes, and spaces are valid."
        ],
        region: [ (v: any) => !!v || "Please select the region." ],
        supervisor: [
            (v: any) =>
                !!v ||
                "Please enter the supervisor of the caseworker assigned to this case",
            (v: any) =>
                /^[a-zA-Z-' ]*$/.test(v) ||
                "Only letters, apostrophes, dashes, and spaces are valid."
        ],
        email: [
            (v: any) =>
                !!v ||
                "Please enter the email address that the LSS staff should use to respond or report issues with request.",
            (v: any) =>
                /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(v) ||
                "Please enter a valid email address."
        ],
        caseID: [
            (v: any) => !!v || "Please enter the Case ID",
            (v: any) =>
                /^[0-9]*$/.test(v) || "The Case ID may only contain numbers."
        ],
        callerName: [
            (v: any) =>
                v.length === 0 || /^[a-zA-Z-' ]*$/.test(v) ||
                "Only letters, apostrophes, dashes, and spaces are valid."
        ],
        officePhone: [
            (v: any) =>
                !!v || "Please enter the phone number of the caller's office.",
            (v: any) =>
                // TODO: Support international numbers
                // /^$|^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/gm.test(
                /^\(\d{3}\) \d{3}\-\d{4}(, \(\d{3}\) \d{3}\-\d{4})*$/.test(
                    v
                ) || "Please enter a valid phone number."
        ],
        mobilePhone: [
            (v: any) =>
                // TODO: Support international numbers
                // /^$|^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/gm.test(
                v.length === 0 || /^\(\d{3}\) \d{3}\-\d{4}(, \(\d{3}\) \d{3}\-\d{4})*$/.test(
                    v
                ) || "Please enter a valid phone number."
        ],
        clientName: [
            (v: any) => !!v || "Please enter the client's name.",
            (v: any) =>
                /^[a-zA-Z-' ]*$/.test(v) ||
                "Only letters, apostrophes, dashes, and spaces are valid."
        ],
        clientPhone: [
            (v: any) => !!v || "Please enter the client's phone number",
            (v: any) =>
                // TODO: Support international numbers
                // /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/g.test(
                /^\(\d{3}\) \d{3}\-\d{4}(, \(\d{3}\) \d{3}\-\d{4})*$/.test(
                    v
                ) || "Please enter a valid phone number."
        ],
        pickupDate: [ (v: any) => !!v || "Please select a pickup date." ],
        adults: [ (v: any) => v >= 1 || "At least one adult is required." ],
        childrenCount: [
            (v: any) =>
                v >= 0 || "There cannot be a negative amount of children."
        ],
        vehicleType: [ (v: any) => !!v || "Please select the vehicle type." ],
        childAge: [ (v: any) => !!v || "Please select the age of the child." ],
        carseats: [
            (v: any) =>
                v >= 0 ||
                "There cannot be a negative amount of carseats required."
        ],
        address: [ (v: any) => !!v || "Address is required." ],
        zip: [
            (v: any) => !!v || "Please enter the zip code.",
            (v: any) => /^[0-9]*$/.test(v) || "Only numbers are valid."
        ],
        time: [ (v: any) => !!v || "Please select the time." ]
    };
}
