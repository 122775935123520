import router from "../router";

import { Role } from "./roles";
import { Org } from "./orgs";

export class User
{
    constructor(
        public name: string,
        public username: string,
        public org: Org,
        public role: Role) {  }

    public static empty()
    {
        return new User("", "", Org.empty(), Role.empty());
    }



    public check_role(role: Role, fail_redirect: string = "")
    {
        const pass = this.role.level <= role.level;
        if(!pass && fail_redirect.length > 0) { router.push({ name: fail_redirect }); }
        return pass;
    }
}
