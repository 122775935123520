




















































































































































































































import { Component, Vue } from "vue-property-decorator";
import { State } from "vuex-class";

import { ITaxiRequest } from "../common/TaxiRequest";
import { military2ampm } from "../common/util";


import ReportSummary from "../components/ReportSummary.vue";
import { get_reports, delete_request, update_request } from "../api";
import { IAlert, toLocaleISOString, User } from "../util";



const searchKeys = [
    "client",
    "case_id",
    "date",
    "start_date",
    "end_date",
    "cab_company"
];
type QueryMap = Record<string, string | (string | null)[]>;
function queryEquals(q1: QueryMap, q2: QueryMap): boolean
{
    const q1Keys = Object.keys(q1);
    if(q1Keys.length !== Object.values(q2).length) { return false; }
    for(const key of q1Keys) {
        if(!(key in q2) || q1[key] !== q2[key]) { return false; }
    }
    return true;
}



@Component({
    components: { ReportSummary }
})
export default class Reports extends Vue
{
    @State user!: User;

    public alert: IAlert = null;

    public date: string = "";
    public menu1: boolean = false;
    public menu2: boolean = false;
    public loading: boolean = false;
    public searched: boolean = false;
    public showModal: boolean = false;
    public range: boolean = false;
    public search: Record<string, string> = {};
    public start_date: string = "";
    public end_date: string = "";
    public deleteModal: boolean = true;


    // TODO: put this in taxi_request model
    public cab_companies = [
        "Arrow",
        "Bobcat",
        "German",
        "United"
    ];
    public reports: ITaxiRequest[] = [];
    public ufn_reports: ITaxiRequest[] = [];
    public non_ufn_reports: ITaxiRequest[] = [];
    public selected: string[] = [];
    public selected_cab_co = "";
    public keysInOrder: (keyof ITaxiRequest)[] = [
        "cab_company",
        "ctrl_number",
        "reason",
        "service",
        "caseworker",
        "caseworker_region",
        "supervisor",
        "caller",
        "email",
        "office_phone",
        "case_id",
        "mobile_phone",
        "client",
        "client_phone",
        "date",
        "adults",
        "children",
        "child_age",
        "vehicle_type",
        "carseats",
        "comments",
        "timestamp",
        "legs"
    ];

    public mounted() {
        const queryEntries = Object.entries(this.$route.query);
        if(!queryEntries || queryEntries.length === 0) { return; }

        for(const [ key, value ] of queryEntries) {
            if(searchKeys.includes(key)) {
                if(key === "date") {
                    this.search.start_date = value as string;
                    this.range = false;
                } else {
                    this.search[key] = value as string;
                    if(key === "start_date" || key === "end_date") {
                        this.range = true;
                    }
                }
            }
        }
        this.fetch_reports();
    }

    public selectReport(report: ITaxiRequest){
        const findIndex = this.selected.indexOf(report._id);
        if(findIndex > -1) {
            this.selected.splice(findIndex, 1);
        } else {
            this.selected.push(report._id);
        }
    }

    async fetch_reports() {
        this.alert = null;
        console.log("Fetching reports...");
        const search = this.makeSearchObject(this.search, this.range);
        if(!queryEquals(search, this.$route.query)) {
            this.$router.push({ path: this.$route.path, query: search });
        }
        this.reports = [];
        this.ufn_reports = [];
        this.non_ufn_reports = [];
        try {
            this.searched = true;
            this.loading = true;
            let result = await get_reports(search);
            // console.log(result);
            if(result.data && result.data.results.length > 0) {
                this.reports = result.data.results;
                for(var idx in this.reports) {
                    if(this.reports[idx]["ufn"]) {
                        this.ufn_reports.push(this.reports[idx]);
                    } else {
                        this.non_ufn_reports.push(this.reports[idx]);
                        // console.log(this.non_ufn_reports[0]["client"]);
                    }
                }
                console.log("Num of reports found:");
                console.log(this.reports.length);
            } else {
                this.reports = [];
            }
            this.loading = false;
        } catch(err) {
            console.error(err);
        }
    }

    private makeSearchObject(form: Record<string, string>, range: boolean) {
        const search = { ...form };
        if(!range && search.start_date) {
            search.date = search.start_date;
            delete search.start_date;
        }
        return search;
    }

    public date_input(bool: boolean) {
        if(bool) {
            const [ year, month, date ] = this.start_date.split("-");
            this.search.start_date = `${month}/${date}/${year}`;
        } else {
            const [ year, month, date ] = this.end_date.split("-");
            this.search.end_date = `${month}/${date}/${year}`;
        }
    }

    async addComment(event: {report: ITaxiRequest, commentField: string}) {
        try {
            // Add comment into comments array
            let report = event.report;
            report.comments.push({
                text: event.commentField,
                author: this.$store.state.user["name"],
                date: new Date().toString()
            });
            // API call
            const _result = await update_request(report);
        } catch(err) {
            console.error(err);
        }
    }

    async delete_request(report: ITaxiRequest) {
        try {
            await delete_request(report._id);
            await this.fetch_reports();
        } catch(err) {
            console.error(err);
        }
    }

    async edit_form(report: ITaxiRequest) {
        try {
            this.$router.push({
                path: "/edit_request",
                query: { _id: (report as any)._id }
            });
        } catch(err) {
            console.error(err);
        }
    }

    async assign_cab() {
        // Go through selected array
        for(const id in this.selected) {
            // becasue the rides are in two different arrays in the presentation logic, search for the id in the original reports
            const ride = this.find_ride(this.selected[id]);
            if(!ride) { continue; }
            try {
                // Fill 'cab_company' field in the form with the one selected
                ride.cab_company = this.selected_cab_co;
                // API call
                await update_request(ride);
                this.alert = {
                    type: "success",
                    message: "Request(s) successfully updated. Please re-run report."
                };
            } catch(err) {
                console.error(err);
                this.alert = {
                    type: "error",
                    message: "There was an error updating your taxi request(s). Please refresh and try again."
                };
            }
        }
        // Make all selected unselected.
        this.selected = [];
    }

    // Function to find the ride by it's _id field in the original reports array from the query results.
    private find_ride(id: string) {
        for(var idx in this.reports) {
            const found_ride = this.reports[idx];
            if(found_ride._id === id) {
                return found_ride;
            }
        }
    }

    public async download_data() {
        if(this.reports != null) {
            let csv = "";
            for(let key of this.keysInOrder) {
                if(key === "timestamp") {
                    csv += "Created,";
                } else if(key === "legs") {
                    csv += "Pickup Address 1" + ",";
                    csv += "Pickup Zip 1" + ",";
                    csv += "Destination Address 1" + ",";
                    csv += "Destination Zip 1" + ",";
                    csv += "Time" + ",";
                    csv += "Will Call" + ",";
                    csv += "Pickup Address 2" + ",";
                    csv += "Pickup Zip 2" + ",";
                    csv += "Destination Address 2" + ",";
                    csv += "Destination Zip 2" + ",";
                    csv += "Time" + ",";
                    csv += "Will Call" + ",";
                    csv += "Pickup Address 3" + ",";
                    csv += "Pickup Zip 3" + ",";
                    csv += "Destination Address 3" + ",";
                    csv += "Destination Zip 3" + ",";
                    csv += "Time" + ",";
                    csv += "Will Call" + ",";
                } else {
                    let formatted_key = key[0].toUpperCase() + key.slice(1);
                    csv += formatted_key + ",";
                }
            }
            csv += "\r\n";
            for(const report of this.reports) {
                for(const key of this.keysInOrder) {
                    if(key === "date") {
                        csv += "\"" + report[key].substr(0, 17) + "\"" + ",";
                    } else if(key === "comments") {
                        const commentBlocks = report[key].map((comment) => `${comment.author} [${comment.date}]: ${comment.text}`);
                        csv += "\"" + commentBlocks.join("\n\n").replace("\"", "\"\"") + "\"" + ",";
                    } else if(key === "timestamp") {
                        csv += report.timestamp ?
                            toLocaleISOString(new Date(report.timestamp)) + "," :
                            "unknown,";
                    } else {
                        if(key == "legs") {
                            for(const data of report["legs"]) {
                                csv += `"${data.pickup.address}",`;
                                csv += `"${data.pickup.zip}",`;
                                csv += `"${data.destination.address}",`;
                                csv += `"${data.destination.zip}",`;
                                csv += `"${military2ampm(data.time)}",`;
                                csv += `"${data.willcall}",`;
                            }
                        } else {
                            csv += "\"" + report[key as keyof ITaxiRequest] + "\"" + ",";
                        }
                    }
                }
                csv += "\r\n";
            }
            // console.log(csv);

            var blob = new Blob([ csv ], { type: "text/csv;charset=utf-8;" });
            if("msSaveBlob" in navigator) {
                (navigator as any).msSaveBlob(blob, "Report.csv");
            } else {
                var link = document.createElement("a");
                if(link.download !== undefined) {
                    var url = URL.createObjectURL(blob);
                    link.setAttribute("href", url);
                    link.setAttribute("download", "Report.csv");
                    link.style.visibility = "hidden";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }
        }
    }
}
