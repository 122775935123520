












































































































































































import { ITaxiRequest } from "@/common/TaxiRequest";
import { TaxiRequest } from "@/model/taxi-request";
import { Component, Vue, Prop } from "vue-property-decorator";

import { IUser } from "../common/User";
import { military2ampm } from "../common/util";


@Component
export default class ReportSummary extends Vue {
    @Prop({required: true}) reports!: TaxiRequest[];
    @Prop({required: true}) selected!: string[];



    public user = this.$store.state.user as IUser;
    public deleteModal = false;
    public commentModal = false;
    public commentField = "";



    public submitComment(report: ITaxiRequest) {
        this.$emit("addComment", { report, commentField: this.commentField });
        this.commentField = "";
        this.commentModal = false;
    }

    public reportFields = [
        {
            label: "Cab Company",
            field: "cab_company"
        },
        {
            label: "Control Number",
            field: "ctrl_number"
        },
        {
            label: "Date",
            field: "date"
        },
        {
            label: "Caseworker",
            field: "caseworker"
        },
        {
            label: "Caseworker Region",
            field: "caseworker_region"
        },
        {
            label: "Supervisor",
            field: "supervisor"
        },
        {
            label: "Reason",
            field: "reason"
        },
        {
            label: "Service",
            field: "service"
        },
        {
            label: "Caller",
            field: "caller"
        },
        {
            label: "Office Phone",
            field: "office_phone"
        },
        {
            label: "Mobile Phone",
            field: "mobile_phone"
        },
        {
            label: "Email",
            field: "email"
        },
        {
            label: "Client Name",
            field: "client"
        },
        {
            label: "Client Phone",
            field: "client_phone"
        },
        {
            label: "Adults",
            field: "adults"
        },
        {
            label: "Children",
            field: "children"
        },
        {
            label: "Ages of Children",
            field: "child_age"
        },
        {
            label: "Carseats",
            field: "carseats"
        },
        {
            label: "Vehicle Type",
            field: "vehicle_type"
        }
    ]

    public prettyDate(date: string | Date) {
        if(!(date instanceof Date)) {
            if(Number.isNaN(Date.parse(date))) {
                return "Unknown Date";
            }
            date = new Date(date);
        }
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    }
    public military2ampm(timestring: string) { return military2ampm(timestring); }
}
