export class Role
{
    constructor(
        public _id: string,
        public name: string,
        public level: number) {  }

    public static default() { return new Role("", "Unknown Role", 100); }
    public static empty()   { return new Role("", "", 100); }
}
