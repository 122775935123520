




















































import { Component, Vue } from "vue-property-decorator";
import {State} from "vuex-class";

import { check_user, select_values, User, SelectOption, ROUTES } from "../util";
import { edit_user, get_roles, get_users } from "../api";



@Component({ })
export default class EditUser extends Vue
{
    @State user!: User;

    public form = {
        name: "",
        password: "",
        role: ""
    };

    public username: string = "";
    public roleOptions: SelectOption[] = [  ];



    public async mounted()
    {
        this.username = this.$route.params.username;
        if(!this.$store.state.roles || this.$store.state.roles.length === 0) {
            await get_roles();
        }
        this.roleOptions = select_values(this.$store.state.roles, "name", "_id");

        this.form.name = this.user.name;
        this.form.role = this.user.role._id;
    }

    // TODO: Should this have validation?
    public async edit_user()
    {
        try {
            const update_obj: { [key: string]: string } = {
                username: this.username
            };

            if(this.form.name != this.user.name) {
                update_obj.name = this.form.name;
            }
            if(this.form.role != this.user.role._id) {
                update_obj.role = this.form.role;
            }
            if(this.form.password) {
                update_obj.password = this.form.password;
            }

            const result = await edit_user(update_obj);
            console.log(result);

            this.$router.push({ name: "manage_users" });
        } catch(err) { console.error(err); }
    }
}
