import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import store from "./store";

import Home from "./views/Home.vue";
import Login from "./views/Login.vue";
import NewReport from "./views/NewReport.vue";
import Reports from "./views/Reports.vue";
import NewUser from "./views/NewUser.vue";
import ManageUsers from "./views/ManageUsers.vue";
import EditUser from "./views/EditUser.vue";
import EditReport from "./views/EditReport.vue";
import { load_user } from "./util";
import VueMeta from "vue-meta";



Vue.use(VueRouter);
Vue.use(VueMeta);

const routes = [
    {
        path: "/",
        name: "home",
        component: Home,
        meta: { requiresUser: true }
    },
    {
        path: "/login",
        name: "login",
        component: Login
    },
    {
        path: "/create_request",
        name: "create_request",
        component: NewReport,
        meta: { requiresUser: true }
    },
    {
        path: "/search_requests",
        name: "search_requests",
        component: Reports,
        meta: { requiresUser: true }
    },
    {
        path: "/create_user",
        name: "create_user",
        component: NewUser,
        meta: {
            requiresUser: true,
            requiresRole: "MANAGER"
        }
    },
    {
        path: "/manage_users",
        name: "manage_users",
        component: ManageUsers,
        meta: {
            requiresUser: true,
            requiresRole: "MANAGER"
        },
        props: true
    },
    {
        path: "/edit_user/:username",
        name: "edit_user",
        component: EditUser,
        props: true,
        meta: {
            requiresUser: true,
            requiresRole: "MANAGER"
        }
    },
    {
        path: "/edit_request",
        name: "edit_request",
        component: EditReport,
        meta: { requiresUser: true },
        props: true
    }
];

export const ROUTES = routes.reduce((acc: any, route: RouteConfig) => {
    acc[route.name!.toUpperCase()] = route.name;
    return acc;
}, {  });

export const PATHS = routes.reduce((acc: any, route: RouteConfig) => {
    acc[route.name!.toUpperCase()] = route.path;
    return acc;
}, {  });

const router = new VueRouter({
    mode: "history",
    base: "/",
    routes
});

router.beforeEach(async (to, _from, next) => {
    if(store.state.user === null) { await load_user(); }

    const requiresUser = to.matched.some((record) => record.meta.requiresUser);
    if(requiresUser) {
        if(store.state.user === null) { return next("/login"); }

        const requiredRole = to.matched.map((record) => record.meta.requiresRole).filter((role) => role)[0];
        if(requiredRole) {
            const role = store.getters.roles[requiredRole];
            if(!store.state.user.check_role(role)){
                return next("/home");
            }
        }
    }
    next();
});

export default router;
