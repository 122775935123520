import Vue from "vue";
import Vuex from "vuex";

import { Role } from "./model/roles";
import { User } from "./model/user";



Vue.use(Vuex);

export interface RootState {
    user: User | null,
    roles: Role[]
}

export default new Vuex.Store({
    state: {
        user: null,
        roles: []
    } as RootState,
    mutations: {
        set_user(state, user) { state.user = user; },
        erase_user(state) { state.user = null; },
        set_roles(state, roles) { state.roles = roles; }
    },
    getters: {
        roles: (state) => {
            return state.roles.reduce((acc, role) => {
                acc[role._id.toUpperCase()] = role;
                return acc;
            }, { } as { [key: string]: Role} );
        }
    }
});
