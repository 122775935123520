





















import { Component, Vue } from "vue-property-decorator";

import ReportForm from "../components/ReportForm.vue";
import { IAlert } from "@/util";

@Component({
    components: { ReportForm }
})
export default class NewReport extends Vue
{
    public modal = false;

    public alert: IAlert = null;
    public closeAction = () => { this.$router.go(0); }



    public successModal() {
        this.alert = {
            type: "success",
            message: "Thank you for your order"
        };
        this.closeAction = () => { this.$router.go(0); };
        this.modal = true;
    }
    public invalidModal() {
        this.alert = {
            type: "warning",
            message: "Some fields were filled out incorrectly."
        };
        this.closeAction = () => { this.modal = false; };
        this.modal = true;
    }
    public errorModal(ev: any) {
        console.log(ev);
        this.alert = {
            type: "error",
            message: "There was an error processing your request."
        };
        this.closeAction = () => { this.modal = false; };
        this.modal = true;
    }
}
