





















import { Component, Vue } from "vue-property-decorator";

import ReportForm from "../components/ReportForm.vue";



type IActionableAlert = null | {
    type: "success" | "info" | "warning" | "error";
    message: string;
    closeLabel: string;
}



@Component({
    components: { ReportForm }
})
export default class EditReport extends Vue
{
    public modal = false;

    public alert: IActionableAlert = null;
    public closeAction = () => { this.$router.go(0); }



    public successModal() {
        this.alert = {
            type: "success",
            message: "Successfully updated your request.",
            closeLabel: "Return to Reports"
        };
        this.closeAction = () => { this.$router.go(-1); };
        this.modal = true;
    }
    public invalidModal() {
        this.alert = {
            type: "warning",
            message: "Some fields were filled out incorrectly.",
            closeLabel: "Close"
        };
        this.closeAction = () => { this.modal = false; };
        this.modal = true;
    }
    public errorModal(ev: any) {
        console.log(ev);
        this.alert = {
            type: "error",
            message: "There was an error processing your request.",
            closeLabel: "Close"
        };
        this.closeAction = () => { this.modal = false; };
        this.modal = true;
    }
}
