import { Validate } from "./base";
import { IRequestComment, ITaxiLeg, ITaxiLocation, ITaxiRequest } from "../common/TaxiRequest";



export type VehicleType = { text: string, value: string, rule?: VehicleRule };
export type VehicleRule = (adults: number, children: number, carseats: number | boolean) => boolean;

export class TaxiRequest implements Validate, ITaxiRequest
{
    public _id!: string; // From a Mongo ObjectId

    public caseworker:        string = "";
    public caseworker_region: string = "";
    public supervisor:        string = ""; // Appears to be some integer/identifier
    public who:               string = ""; // Hidden information of current website user

    public caller:        string = ""; // The person calling LSS
    public office_phone:  string = ""; // Their phone number
    public mobile_phone?: string = ""; // Optional
    public email:         string = ""; // Email LSS staff can contact concerning issues with the request

    public client:       string  = ""; // The passenger
    public client_phone: string  = "";
    public adults:       number  = 1; // Integer, defaults to 1
    public children:     number  = 0; // Integer, defaults to 0
    public child_age?:   string; // Ages of the children
    public carseats:     number | boolean = 0;
    public vehicle_type: string  = "";
    public comments:     IRequestComment[] = [  ];

    public reason:       string = "";
    public service:      string = "";
    public called:       boolean | string = false; // String if data unclear

    public ufn?:         boolean = false; // Null if data unclear
    public cab_company?: string;
    public ctrl_number?: string;
    public record?:      string;
    public case_id? :     string;
    public date: string;      // formatted as MM/DD/YYYY
    public legs: TaxiLeg[]; // See class TaxiLeg

    // This value is never read unless it's returned by the server.
    public timestamp = 0;


    public static REASON_DEFAULTS = [
        "Client not on bus line",
        "Client has physical/medical limitations",
        "Bus Pass Unavailable",
        "Time Constraints",
        "Other"
    ];
    public static SERVICE_DEFAULTS = [
        "Counseling",
        "Doctor's Appointment",
        "Court Hearing",
        "Visitation",
        "Drug Screening",
        "Case Meeting",
        "Material Assistance",
        "Other"
    ];
    public static REGIONS = [
        "Adoption",
        "Children's Advocacy Center",
        "East",
        "Youth Transitions",
        "Intake",
        "Kinship",
        "West"
    ];
    public static VEHICLE_TYPES: VehicleType[] = [
        {
            text: "Sedan",
            value: "Sedan",
            rule(adults: number, children: number, carseats: number | boolean) {
                const seats = typeof carseats === "boolean" ? (carseats ? 1 : 0) : carseats;
                if(adults + children > 4) { return true; } // Enforce city code capacity requirements
                if(seats >= 3) { return true; }            // Enforce carseat capacity requirements
                return false;
            }
        },
        { text: "Minivan", value: "Minivan" },
        { text: "Large Van", value: "Large Van" },
        { text: "Handicap Accessible Vehicle", value: "Handicap Accessible Vehicle" }
    ];

    public static vehicleTypeArray =[
        "Sedan",
        "Minivan",
        "Large Van",
        "Handicap Accessible Vehicle"
    ]

    public constructor(date: string, legs: TaxiLeg[]);
    public constructor(obj: any);
    public constructor(obj: any, legs?: TaxiLeg[])
    {
        if(legs)
        {
            this.date = obj;
            this.legs = legs;
        }
        else
        {
            this._id = obj._id;

            this.caseworker        = obj.caseworker;
            this.supervisor        = obj.supervisor;
            this.caseworker_region = obj.caseworker_region;
            this.who               = obj.who;

            this.caller        = obj.caller;
            this.email         = obj.email;
            this.office_phone  = obj.office_phone;
            this.mobile_phone  = obj.mobile_phone;
            this.client_phone  = obj.client_phone;

            this.client       = obj.client;
            this.adults       = obj.adults;
            this.children     = obj.children;
            if(obj.child_age) { this.child_age = obj.child_age; }
            this.carseats     = obj.carseats;
            this.vehicle_type = obj.vehicle_type;
            this.comments     = obj.comments;

            this.reason  = obj.reason;
            this.service = obj.service;
            this.called  = obj.called;

            if(obj.ufn)         { this.ufn         = obj.ufn;         }
            if(obj.cab_company) { this.cab_company = obj.cab_company; }
            if(obj.ctrl_number) { this.ctrl_number = obj.ctrl_number; }
            if(obj.record)      { this.record      = obj.record;      }
            if(obj.case_id)     { this.case_id     = obj.case_id;     }

            this.date = obj.date;
            this.legs = obj.legs.map((legobj: any) => new TaxiLeg(legobj));
        }
    }

    public static default(): TaxiRequest
    {
        const date = "";

        const legs = [ TaxiLeg.default() ];

        return new TaxiRequest(date, legs);
    }



    public validate(): boolean
    {
        // TODO: actually validate
        return true;
    }
}

export class TaxiLeg implements Validate, ITaxiLeg
{
    time = ""; // of form "HH:mm"
    willcall = "No" as ("Yes" | "No");

    pickup:      TaxiLocation;
    destination: TaxiLocation;


    public constructor(obj: any);
    public constructor(pickup: TaxiLocation, destination: TaxiLocation);
    public constructor(obj: any, destination?: TaxiLocation)
    {
        if(destination)
        {
            this.pickup = obj as TaxiLocation;
            this.destination = destination;
        }
        else
        {
            this.time = obj.time;
            this.willcall = obj.willcall;

            this.pickup      = new TaxiLocation(obj.pickup);
            this.destination = new TaxiLocation(obj.destination);
        }
    }
    public reverse(): TaxiLeg
    {
        const rev = new TaxiLeg(this);
        rev.pickup = new TaxiLocation(this.destination);
        rev.destination = new TaxiLocation(this.pickup);
        return rev;
    }

    public static default(willcall: ("Yes" | "No") = "No"): TaxiLeg
    {
        const leg = new TaxiLeg(TaxiLocation.default(), TaxiLocation.default());
        leg.willcall = willcall;
        return leg;
    }



    public validate(): boolean
    {
        // TODO: actually validate
        return true;
    }
}

export class TaxiLocation implements Validate, ITaxiLocation
{
    address: string;
    zip:     number; // Integer, valid postal code


    public constructor(obj: any);
    public constructor(address: string, zip: number);
    public constructor(obj: any, zip?: number)
    {
        if(zip !== undefined)
        {
            this.address = obj as string;
            this.zip     = zip;
        }
        else
        {
            this.address = obj.address;
            this.zip     = parseInt(obj.zip);
        }
    }

    public static default(): TaxiLocation
    {
        // Nasty casting to have text fields start empty.
        return new TaxiLocation("", "" as unknown as number);
    }



    public validate(): boolean
    {
        // TODO: actually validate
        return true;
    }
}
