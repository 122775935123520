import axios, { AxiosResponse, AxiosError } from "axios";
import { get as getCookie } from "browser-cookies";

import store from "./store";
import { load_user } from "./util";

import { TaxiRequest } from "./model/taxi-request";
import { Org } from "./model/orgs";
import { ITaxiRequest } from "./common/TaxiRequest";



const ENDPOINTS = {
    TAXI_REQUEST:   "/api/taxi/request",
    UPDATE_REQUEST: "api/taxi/request",
    REPORT_SEARCH:  "/api/taxi/search",
    LOGIN:          "/api/user/login",
    USER:           "/api/user",
    ORGS:           "/api/user/orgs",
    ROLES:          "/api/user/roles"
} as const;

export class APIError extends Error {  }



export const submit_taxi_request = async (data: TaxiRequest) => {
    return axios.post(ENDPOINTS.TAXI_REQUEST, data, { withCredentials: true });
};
export const edit_request = async (data: any) => {
    return axios.patch(ENDPOINTS.TAXI_REQUEST, data, { withCredentials: true });
};
export const delete_request = async (id: any) => {
    return axios.delete(ENDPOINTS.TAXI_REQUEST + "/" + id, { withCredentials: true });
};
export const get_request = async (id: any) => {
    return axios.get(ENDPOINTS.TAXI_REQUEST + "/" + id, { withCredentials: true })
        .then((res) => {
            if(res.data.status) {
                if(res.data.status === "success") {
                    return res.data.request as ITaxiRequest;
                }
                throw new Error(res.data.message);
            }
            throw new Error(res.data);
        });
};

// TODO: make class for form object
export const get_reports = async (params: { [key: string]: string }) => {
    return axios.get(ENDPOINTS.REPORT_SEARCH, { params, withCredentials: true });
};

export const update_request = async (data: any) => {
    return axios.patch(ENDPOINTS.UPDATE_REQUEST, data, { withCredentials: true});
};


export const get_roles = async () => {
    return axios.get(ENDPOINTS.ROLES, { withCredentials: true });
};

export const add_org = async (data: Org) => {
    return axios.post(ENDPOINTS.ORGS, data, { withCredentials: true});
};

type LoginData = { username: string, password: string };

export const login = async (data: LoginData) => {
    try {
        // JWT Token is automatically stored as a cookie
        const response = await axios.post(ENDPOINTS.LOGIN, data);
        if(response.status !== 200) {
            return { ok: false, message: "Unknown Server Error. Try Again." };
        }

        // Retreive user from the cookie...
        const user = await load_user();
        // console.log(user);
        return { ok: true };
    } catch(err) {
        if(axios.isAxiosError(err)) {
            if(err.response?.data?.message) {
                throw new APIError(err.response.data.message);
            }
        } else { throw err; }
    }
};



export const create_user = async (data: any) => {
    return axios.post(ENDPOINTS.USER + "/", data, { withCredentials: true });
};

export const edit_user = async (data: any) => {
    return axios.patch(ENDPOINTS.USER + "/", data, { withCredentials: true });
};

export const delete_user = async (username: string) => {
    return axios.delete(ENDPOINTS.USER + "/" + username, { withCredentials: true });
};

export const get_users = async () => {
    return axios.get(ENDPOINTS.USER, { withCredentials: true});
};

export const get_orgs = async () => {
    return axios.get(ENDPOINTS.ORGS, { withCredentials: true });
};
